export const entries = [
    {
        id:  '1',
        title: 'First Entry',
        description: 'This is the first entry',
    },
    {
        id:  '2',
        title: 'Second Entry',
        description: 'This is the second entry',
    },
    {
        id:  '3',
        title: 'Third Entry',
        description: 'This is the third entry',
    },
]