import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { entries } from '../data';
//import { Scene } from 'three';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'

interface RouteParams {
  id: string;
}



const EntryPage: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const entry = entries.find((entry) => entry.id == id);
  if(!entry) {
    throw new Error(`No such entry: ${id}`);
  }

  const [scene, setScene] = useState(new THREE.Scene());
  const [light, setLight] = useState(new THREE.PointLight());
  const [camera, setCamera] = useState(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
  const [renderer, setRenderer] = useState(new THREE.WebGLRenderer());
  const [controls, setControls] = useState(new OrbitControls(camera, renderer.domElement));
  const [container, setContainer] = useState(document.getElementById( 'canvas' ));
  
  
  

  function init() {
 
    camera.position.z = 1;
    
    light.position.set(2.5, 7.5, 15)
    scene.add(light);
   
    renderer.setSize(window.innerWidth, window.innerHeight);

    const mtlLoader = new MTLLoader();
    mtlLoader.load('/assets/3d/head1/model.mtl',
      (materials) => {
        materials.preload();

        const objLoader: OBJLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.load(
          '/assets/3d/head1/model.obj',
            (object) => {
                scene.add(object);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded');
            },
            (error) => {
                console.log('An error happened');
            }
        );
      },
      (xhr) => {
          console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      },
      (error) => {
          console.log('An error happened');
    });

    var newContaier = document.getElementById( 'canvas' );
    newContaier.appendChild( renderer.domElement );
    setContainer(newContaier);

  }

  function animate() {
 
    requestAnimationFrame( animate );
  
    controls.update();

    renderer.render( scene, camera );
 
  }

  window.addEventListener('resize', onWindowResize, false)
  function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
      renderer.render( scene, camera );
  }
 

  useIonViewDidEnter(() => {
    console.log('ionViewDidEnter event fired');
    animate();
  });

  useIonViewDidLeave(() => {
    console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
    init();
  });

  useIonViewWillLeave(() => {
    console.log('ionViewWillLeave event fired');
  });


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle> Your 3d model </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div id="canvas"></div>
      </IonContent>
    </IonPage>
  );
};



export default EntryPage;
