import {
  IonButton,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { entries } from '../data';





type Item = {
  src: string;
  text: string;
  id: string;
};
const items: Item[] = [
  // { src: 'http://placekitten.com/g/200/300', text: 'a picture of a cat' },
  { src: 'assets/img/testHead1.jpg', text: 'test 1', id: '1' }
];



const HomePage: React.FC = () => {

  const [imagePath, setImagePath] = useState(null);



  function showRandomImage() {

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {/* <IonList>
          { entries.map((entry) => 
            <IonItem key={entry.id} 
              routerLink={`/my/entries/${entry.id}`}>
              { entry.title }
              </IonItem>
          )}
        </IonList> */}
        <IonList>
          {items.map((image, i) => (
            <IonItem key={image.id} routerLink={`/my/entries/${image.id}`} >
              <IonThumbnail slot="start">
                <IonImg src={image.src} />
              </IonThumbnail>
              <IonLabel >{image.text}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonButton expand="block" onClick={ showRandomImage }>Take a selfie</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
